import React, { useEffect, useState } from "react";
import NewGroupForm from "../../Form/NewGroupForm";
import { Button, Form, notification, Row, Input, Col } from "antd";
import {
    BackwardFilled,
    BackwardOutlined,
    CloseCircleOutlined,
    ArrowLeftOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import Apicall from "../../Helper/Apicall";
import { useNavigate } from "react-router-dom";
import SubGroupForm from "../../Form/SubGroupForm";
import NotificationHandler from "../../Helper/NotificationHandler";
import Login from "../Login/Login";

const NewSubGroup = ({ groupId, afterSubmission }) => {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [subGroupData, setSubGroupData] = useState({
        subGroupName: "",
        deviceId: "",
        isStart: true,
        isAutoLogin: false,
    });
    const [toUpdate, setToUpdate] = useState(false);

    const handleNewSubGroup = async () => {
        try {
            if (!toUpdate)
                return NotificationHandler.error("Nothing to Update");
            let data = await Apicall("post", "addSubGroup", subGroupData);
            if (data.success === 1) {
                afterSubmission();
            } else {
                NotificationHandler.error(data.message);
                afterSubmission();
            }
        } catch (error) {
            notification.error(error.message);
            afterSubmission();
        }
    };

    const getSubGroupName = async () => {
        try {
            const data = await Apicall("post", "getSubGroupName", {
                groupId: groupId,
            });
            setSubGroupData({ ...subGroupData, subGroupName: data.data });
        } catch (error) {
            notification.error(error.message);
        }
    };
    const onValuesChange = (changedValue, allValue) => {
        setToUpdate(true);
        setSubGroupData((previouState) => ({
            ...previouState,
            deviceId: changedValue.deviceId,
            groupId: groupId,
        }));
    };
    useEffect(() => {
        getSubGroupName();
    }, []);
    return (
        <div>
            <Row>
                <Col span={10}>
                    <h3>New Sub Group</h3>
                </Col>
            </Row>
            {contextHolder}
            {subGroupData.subGroupName ? (
                <Form
                    name="newGroupForm"
                    form={form}
                    layout="horizontal"
                    onFinish={handleNewSubGroup}
                    onValuesChange={onValuesChange}
                    initialValues={{
                        subGroupName: subGroupData.subGroupName,
                        deviceId: subGroupData.deviceId,
                    }}
                >
                    <SubGroupForm current={form} />
                    <Button
                        htmlType="submit"
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        ADD
                    </Button>
                </Form>
            ) : (
                <>Loading Please Wait</>
            )}
        </div>
    );
};

export default NewSubGroup;
