import React, { useEffect, useState } from "react";
import { Button, Table, Row } from "antd";
import { templateColumns } from "../../Data/TemplateData";
import { useNavigate } from "react-router-dom";
import Apicall from "../../Helper/Apicall";
import { NotificationFilled } from "@ant-design/icons";
import NotificationHandler from "../../Helper/NotificationHandler";

const TemplateList = () => {
    const [data, setData] = useState([]);

    const getGroupList = async () => {
        let templateData = await Apicall("post", "getAllTemplate", {});
        if (templateData.success === 0) {
            NotificationHandler.error(templateData.message);
        } else {
            setData(templateData.data);
        }
    };
    const navigate = useNavigate();
    const handleNewGroupClick = () => {
        navigate("/addNewTemplate");
    };
    useEffect(() => {
        getGroupList();
    }, []);
    return (
        <div className="home_center">
            <Row justify={"center"} style={{ padding: "1rem" }}>
                <Button type="primary" onClick={handleNewGroupClick}>
                    Create New Template
                </Button>
            </Row>
            <Table columns={templateColumns}  dataSource={data}/>
        </div>
    );
};

export default TemplateList;
