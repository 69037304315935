import { Form, Input, Select,Col } from 'antd'
import React, { useState } from 'react'
import Apicall from '../Helper/Apicall'

const SubGroupForm = ({ current }) => {
    const handleItemChange = (e) => {
        current.setFieldsValue({ groupId: e.target.value });
    };

    return (
        <div>
            <Form.Item
                label="Sub Group"
                name={"subGroupName"}
                labelAlign="left"
                labelCol={{ span: 8 }}
                rules={[
                    {
                        required: true,
                        message: "Plase Fill Sub Group Name",
                    },
                ]}
            >
                <Input  />
            </Form.Item>
            <Form.Item
                label={"Device Id"}
                labelAlign="left"
                labelCol={{ span: 8 }}
                name={"deviceId"}
                rules={[
                    {
                        required: true,
                        message: "Plase Fill Device Id Name",
                    },
                ]}
            >
                <Input onChange={(e) => handleItemChange} />
            </Form.Item>
        </div>
    );
};

export default SubGroupForm
