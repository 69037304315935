import React, { useState } from "react";
import { Input, Row, Button, notification, message } from "antd";
import { useNavigate } from "react-router-dom";
import Apicall from "../../Helper/Apicall";
import "../../App.css";

const Login = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const handelLogin = async () => {
        let paylod = { userName: userName, password: password };
        if (!userName || !password) {
            return alert("please fill all value");
        }
        let response = await Apicall("post", "login", paylod);
        if (response.success === 0) {
            api.error({ message: response.message });
        } else {
            api.info({ message: response.message });
            window.location.replace("/");
            localStorage.setItem("token", response.token);
        }
    };

    return (
        <div className="login_div">
            <div className="centered_div">
                {contextHolder}
                <h2> AUTOBOT-DASHBOARD </h2>

                <Input
                    className="input"
                    placeholder="enter username"
                    size="large"
                    onChange={(e) => {
                        setUserName(e.target.value);
                    }}
                />
                <Input
                    className="input"
                    size="large"
                    type="password"
                    placeholder="password"
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
                <Row justify={"center"}>
                <Button 
                type="primary"
                 style={{
                    width:"100px"
                }}  size = {"large"}onClick={handelLogin}>
                    LOGIN
                </Button>
                </Row>
           
            </div>
        </div>
    );
};

export default Login;
