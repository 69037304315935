import "./App.css";
import Home from "./Components/Home";
import Login from "./Components/Login/Login.js";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Newuser from "./Components/UserManagment/Newuser.js";
import Header from "../src/Components/Header";
import Updateuser from "./Components/UserManagment/Updateuser.js";
import TargetWebsite from "./Components/SiteManagment/AddWebsite.js";
import Listtargetwebsite from "./Components/SiteManagment/Listtargetwebsite.js";
import Addbet from "./Components/BetMangment/AddConfig.js";
import NewJob from "./Components/JobManagment/NewJob.js";
import BetHistory from "./Components/BetMangment/BetHistory.js";
import BetList from "./Components/BetMangment/BetList.js";
import TimeManagement from "./Components/TimeManagement.js";
import Sidebar from "./Components/Sidebar.js";
import Dashbord from "./Components/OtherComponent/Dashbord.js";
import GroupList from "./Components/GroupMaster/GroupList.jsx";
import NewGroup from "./Components/GroupMaster/NewGroup.jsx";
import SubGroupList from "./Components/GroupMaster/SubGroupList.jsx";
import NewSubGroup from "./Components/GroupMaster/NewSubGroup.jsx";
import TemplateList from "./Components/Template/TemplateList.jsx";
import NewTemplate from "./Components/Template/NewTemplate.jsx";
import EditGroup from "./Components/GroupMaster/EditGroup.jsx";
import EditSubGroup from "./Components/GroupMaster/EditSubGroup.jsx";
import EditTemplate from "./Components/Template/EditTemplate.jsx";
import AddNumber from "./Components/Numbers/AddNumber.js"

function App() {
    const token = localStorage.getItem("token");

    return (
        <div>
            <BrowserRouter>
                {token ? (
                    <div>
                        <Header />
                        <Home
                            sidebar={<Sidebar />}
                            content={
                                <div>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<Dashbord />}
                                        />
                                        <Route
                                            path="/groupSubGroupList"
                                            element={<GroupList />}
                                        />
                                        <Route
                                            path="/addNewGroup"
                                            element={<NewGroup />}
                                        />
                                        <Route   path="/edit/group/:groupId" element = {<EditGroup />}/>
                                        <Route
                                            path="/subGroupList"
                                            element={<SubGroupList />}
                                        />
                                        <Route
                                            path="/addNewSubGroup"
                                            element={<NewSubGroup />}
                                        />
                                        <Route   path="/edit/subGroup/:subGroupId" element = {<EditSubGroup />}/>

                                        <Route
                                            path="/templateList"
                                            element={<TemplateList />}
                                        />
                                        <Route
                                            path="/addNewTemplate"
                                            element={<NewTemplate />}
                                        />
                                            <Route
                                            path="/edit/template/:templateId"
                                            element={<EditTemplate />}
                                        />
                                        <Route
                                            path="/newUser"
                                            element={<Newuser />}
                                        />
                                        <Route
                                            path="/editUser"
                                            element={<Updateuser />}
                                        />
                                        <Route
                                            path="/addWebsite"
                                            element={<TargetWebsite />}
                                        />
                                        <Route
                                            path="/editWebsite"
                                            element={<Listtargetwebsite />}
                                        />
                                        <Route
                                            path="/addConfig"
                                            element={<Addbet />}
                                        />
                                        <Route
                                            path="/newJob"
                                            element={<NewJob />}
                                        />
                                        <Route
                                            path="/betList/:jobId"
                                            element={<BetList />}
                                        />
                                        <Route
                                            path="/betHistory/:jobId"
                                            element={<BetHistory />}
                                        />
                                        <Route
                                            path="/marketTime"
                                            element={<TimeManagement />}
                                        />
                                        <Route
                                        path="/addNumber"
                                        element ={<AddNumber />}
                                         />
                                    </Routes>
                                </div>
                            }
                        />
                    </div>
                ) : (
                    <div>
                        <Routes>
                            <Route path="/" element={<Login />} />
                        </Routes>
                    </div>
                )}
            </BrowserRouter>
        </div>
    );
}

export default App;
