import React, { useEffect, useState } from "react";
import NewGroupForm from "../../Form/NewGroupForm";
import { Row, Form, Col, Button, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Apicall from "../../Helper/Apicall";
import { useNavigate } from "react-router-dom";
import NewSubGroup from "./NewSubGroup";
import SubGroupForm from "../../Form/SubGroupForm";
import NotificationHandler from "../../Helper/NotificationHandler";

const EditSubGroup = () => {
    const [data, setData] = useState(false);
    const [groupName, setGroupName] = useState(false);
    const [subGroupName, setSubGroupName] = useState(false);
    const [deviceId,setDeviceId] = useState(false)
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { subGroupId } = useParams("subGroupId");
    const getSubGroupData = async () => {
        let data = await Apicall("post", "getGroupAndSubGroup", {
            subGroupId: subGroupId,
        });
        if (data.success === 1) {
            setData(data.data[0]);
            setGroupName(data.data[0].groupName);
            setSubGroupName(data.data[0].subGroup[0].subGroupName);
            setDeviceId(data.data[0].subGroup[0].deviceId)
            
        } else {
        }
    };
    useEffect(() => {
        getSubGroupData();
    }, []);

    const handleFormUpdate = async (value) => {
        if (value.groupId === groupName) {
            delete value.groupId;
        }
        value.subGroupId = subGroupId;
        let data = await Apicall("post", "updateGroup", value);
        if (data.success === 1) {
            navigate("/groupSubGroupList");
        }else{
            NotificationHandler.error(data.message)
        }
    };
    return (
        <div className="home_center">
            <Row
                style={{
                    margin: "2rem",
                }}
                justify
            >
                <ArrowLeftOutlined
                    onClick={() => {
                        navigate("/groupSubGroupList");
                    }}
                />
                <Col span={10}>
                    <h>Edit Sub Group</h>
                </Col>
            </Row>
            { groupName ? (
                <Form
                    form={form}
                    name="editSubGroup"
                    layout="vertical"
                    initialValues={{
                        groupId: groupName,
                        subGroupName: subGroupName,
                        deviceId:deviceId

                    }}
                    onFinish={handleFormUpdate}
                >
                    <SubGroupForm type="edit"  current={form} />
                    <Row style={{ margin: "1rem" }} justify={"center"}>
                        <Button htmlType="submit" type="primary">
                            UPDATE
                        </Button>
                    </Row>
                </Form>
            ) : (
                <Spin  />
            )}
        </div>
    );
};

export default EditSubGroup;
