import React from "react";
import { Alert,Spin } from "antd";

const Loader = ({ message ,width}) => {
    return (
        <div className="home_center" style={{width: `${width}vw` || "100vw"}}>
            <Spin
                contentHeight={800}
                direction="vertical"
                style={{
                    width: `${width}vw`,
                }}
            >
                <Alert message={message} type="info" />
            </Spin>
        </div>
    );
};

export default Loader;
