import React from 'react'
import { Form ,Input} from 'antd'

const  NewGroupForm = () => {
  return (
    <div>
      <Form.Item  label ="Group Name" name={"groupName"} rules={[
        {
            required:true,
            message:"Please Fill the Group Name"
        }
      ]}>
        <Input style={{width:500}}   />
      </Form.Item>
    </div>
  )
}

export default NewGroupForm
