import { Button, Table, notification, Modal, Input, Select,Col,Row } from "antd";
import React, { useEffect, useState } from "react"
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import Apicall from "../../Helper/Apicall";
import Loader from "../../Helper/Loader";

const Updateuser = () => {
    const [data, setData] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpen, setIsModalOpen] = useState(false); // state of Modal
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading,setLoading] = useState(false)
    const [modalLoader ,setModalLoader] = useState(false)
    const navigate = useNavigate()

    const [oldUser, setOldUser] = useState({
        userName: "",
        role: "",
        userId: "",
        password: "",
    });

    // OnCancel of Modal
    const handleModalCancel = () => {
        setIsModalOpen(false);
        setOldUser({
            userName: "",
            role: "",
            userId: "",
            password: "",
        });
    };

    const handelModalOk = async () => {
        try {
            let payload = {
                userName: oldUser.userName,
                role: oldUser.role,
                password: oldUser.password,
                userId: oldUser.userId,
            };

            setModalLoader(true);
            let response = await Apicall("post", "updateUser", payload);
            if (response.success === 0) {
                throw new Error(response.message);
            } else {
                api.info({ message: response.message, duration: 1 });
                setTimeout(() => {
                    setIsModalOpen(false);
                }, 500);
                setOldUser({
                    userName: "",
                    role: "",
                    userId: "",
                    password: "",
                });
                setModalLoader(false)
            }
        } catch (error) {
            api.error({ message: error.message, duration: 1.5 });
            setModalLoader(false);
        }
    };

    const getAllUser = async () => {
        try {
            setLoading(true);
            let response = await Apicall("get", "allUser", "");
            if (response.success === 0) {
                throw new Error(response.message);
            } else {
                setData(response.data);
                setLoading(false);
            }
        } catch (error) {
            api.error({ message: error.message });
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllUser();
    }, []);

    const editUser = (record) => {
        setOldUser({
            ...oldUser,
            userName: record.userName,
            role: record.role,
            userId: record.userId,
        });
        setIsModalOpen(true);
    };

    const columns = [
        {
            title: "User Name",
            dataIndex: "userName",
            key: "userId",
        },
        {
            title: "Postion",
            dataIndex: "role",
            key: "userId",
        },
        {
            title: "Created Date",
            dataIndex: "joinDate",
            key: "userId",
        },

        {
            title: "Action",
            key: "operation",
            render: (_, record) => {
                return <Button onClick={() => editUser(record)}>Edit </Button>;
            },
        },
    ];
    return (
        <div>
            {loading ? (
                <Loader message={"Please Wait"} />
            ) : (
                <div className="home_center">
                    <Row style={{padding:"10px"}}>
                        <Col span={12}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                <LeftOutlined />
                                Back
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Refresh
                            </Button>
                        </Col>
                    </Row>
                    {contextHolder}
                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{
                            y: 500,
                        }}
                    />

                    <Modal
                        title={`EDIT USER - ${oldUser.userName}`}
                        open={isModalOpen}
                        closeIcon={false}
                        onCancel={handleModalCancel}
                        onOk={handelModalOk}
                        confirmLoading={confirmLoading}
                    >
                        {modalLoader ? (
                            <div>
                                <Loader
                                    message={"Updating User Please Wait"}
                                    width={30}
                                />
                            </div>
                        ) : (
                            <div>
                                Name:{" "}
                                <Input
                                    className="input"
                                    value={oldUser.userName}
                                    onChange={(e) =>
                                        setOldUser({
                                            ...oldUser,
                                            userName: e.target.value,
                                        })
                                    }
                                />
                                Password:
                                <Input
                                    className="input"
                                    value={oldUser.password}
                                    onChange={(e) =>
                                        setOldUser({
                                            ...oldUser,
                                            password: e.target.value,
                                        })
                                    }
                                />
                                Role:{" "}
                                <Select
                                    value={oldUser.role}
                                    placeholder="select user Role"
                                    style={{
                                        width: 400,
                                    }}
                                    onChange={(value) => {
                                        setOldUser({ ...oldUser, role: value });
                                    }}
                                    options={[
                                        {
                                            value: "USER",
                                            label: "User",
                                        },
                                        {
                                            value: "ADMIN",
                                            label: "Admin",
                                        },
                                    ]}
                                />
                            </div>
                        )}
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Updateuser;
