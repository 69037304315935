const TimeManagement = () => {
    return (
        <>
            <div className="home_center">
                <h2>Time Management</h2>
            </div>
        </>
    );
};

export default TimeManagement;
