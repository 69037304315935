    import React, { useEffect, useState } from "react";
    import NewGroupForm from "../../Form/NewGroupForm";
    import { Row, Form, Col, Button } from "antd";
    import { ArrowLeftOutlined } from "@ant-design/icons";
    import { useParams } from "react-router-dom";
    import Apicall from "../../Helper/Apicall";
    import { useNavigate } from "react-router-dom";
    import NotificationHandler from "../../Helper/NotificationHandler";

    const EditGroup = () => {
        const [groupName, setGroupName] = useState("");
        const navigate = useNavigate();
        const [form] = Form.useForm();
        const { groupId } = useParams("groupId");

        const getGroupData = async () => {
            let data = await Apicall("post", "getGroupAndSubGroup", {
                groupId: groupId,
            });
            if (data.success === 1) {
                setGroupName(data.data[0].groupName);
            } else {
                NotificationHandler.error(data.message);
            }
        };
        useEffect(() => {
            getGroupData();
        }, []);

        const handleFormUpdate = async (value) => {
            value.groupId = groupId;
            let data = await Apicall("post", "updateGroup", value);
            if (data.success === 1) {
                navigate("/groupSubGroupList");
            } else {
                NotificationHandler.error(data.message);
            }
        };

        return (
            <div className="home_center">
                <Row
                    style={{
                        margin: "2rem",
                    }}
                    justify
                >
                    <ArrowLeftOutlined
                        onClick={() => {
                            navigate("/groupSubGroupList");
                        }}
                    />
                    <Col span={10}>
                        <h>Edit Group</h>
                    </Col>
                </Row>
                {groupName ? (
                    <Form
                        form={form}
                        name="editGroup"
                        layout="vertical"
                        initialValues={{ groupName: groupName }}
                        onFinish={handleFormUpdate}
                    >
                        <NewGroupForm />
                        <Row style={{ margin: "1rem" }} justify={"center"}>
                            <Button htmlType="submit" type="primary">
                                UPDATE
                            </Button>
                        </Row>
                    </Form>
                ) : (
                    ""
                )}

                <Row></Row>
            </div>
        );
    };

    export default EditGroup;
