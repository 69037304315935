import { Button } from "antd";
import EditComponent from "../Components/EditComponent";
export const templateColumns = [
    {
        title: "SR NO",
        dataIndex: "srNo",
        key: "srNo",
    },
    {
        title: "Template Category Name",
        dataIndex: "label",
        key: "templateName",
    },
    {
        title: "Template Category Code",
        dataIndex: "templateCategoryCode",
        key: "templateName",
    },
    {
        title: "Action",
        render: (_, menu) => {
            return <EditComponent page={"template"} pageId={menu.value} />;
        },
    },
];

export const minimumBet = [
    {
        label: 100,
        value: 100
    },
    {
        label: 500,
        value:500
    },
    {
        label: 1000,
        value:1000
    },
];
