import React, { useEffect, useState } from "react";
import { Button, Row, Col, Dropdown, Popover, Avatar ,Typography, Spin,Badge} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { BellFilled, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import Apicall from "../Helper/Apicall";
const {Text} = Typography

const Header = () => {
    const [logedin, setLogedin] = useState(false);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [notification, setNotification] = useState([]);
    const [count, setCount] = useState(0);

    const logOutUser = () => {
        localStorage.removeItem("token");
    };

    const checkLogin = async () => {
        let token = localStorage.getItem("token");
        //call the authenticate api here to check the token validity
        try {
            let response = await Apicall("post", "authenticate");
            if (response.success === 1) {
                setName(response.data.userName);
                setLogedin(true);
            } else {
                localStorage.clear();
                window.location.replace("/");
            }
        } catch (error) {}
    };

    const getNotification = async () => {
        const response = await Apicall("post", "getNotifications", {
            userName: name,
        });

        setNotification((prevNotification) => {
            if (response.success) {
                const temData = response.data;
                temData.length > prevNotification.length
                    ? setCount(1)
                    : setCount(0);
                return temData;
            } else {
                return prevNotification;
            }
        });
    };

  

    useEffect(() => {
        checkLogin();
        // Clean up the interval when the component unmounts
        setInterval(getNotification,180000)

    }, []);

    

    const items = [
        {
            label: (
                <p
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flex: "0.5",
                    }}
                >
                    <Avatar
                        style={{
                            backgroundColor: "#fde3cf",
                            color: "#f56a00",
                        }}
                        icon={<UserOutlined />}
                    />
                    <h6> {name.split("@")[0]}</h6>
                </p>
            ),
        },
        {
            label: (
                <Button type="primary">
                    <a onClick={logOutUser} href="/">
                        Logout
                    </a>
                </Button>
            ),
            key: "1",
        },
    ];

    const Content = (
        <Col gutter={[16, 16]} style={{ height: 500, width: 400 ,overflowY:"auto"}}>
            {notification.length > 0 ? (
                <>
                    {notification.map((item, index) => (
                        <Col span={24} key={item.notificationId}>
                            <Row
                                style={{
                                    justifyContent: "left",
                                    alignItems: "center",
                                    padding: 5,
                                }}
                            >
                                <Col span={4}>
                                    <Avatar size={"large"}>
                                        {item.userName.toUpperCase()}
                                    </Avatar>
                                </Col>
                                <Col span={20}>
                                    <Text
                                        type={
                                            item.type === "failed"
                                                ? "danger"
                                                : "success"
                                        }
                                    >
                                        {item.message}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </>
            ) : (
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <Text type="warning">NO NOTIFICATION FOUNS</Text>
                </div>
            )}
        </Col>
    );

    return (
        <div className="header">
            <div className="bottom_border">
                <Row align={"middle"} justify={"center"}>
                    <Col span={12}>
                        <h1
                            style={{
                                fontFamily: "Arial ",
                                fontSize: "4vh",
                            }}
                        >
                            <Link to={"/"}>AUTOBOT</Link>
                        </h1>
                    </Col>
                    {/* <Col span={2}>
                        <Popover
                            placement="bottom"
                            content={userContent}
                            trigger={["hover"]}
                        >
                            <p type="primary">User-Management</p>
                        </Popover>
                    </Col>
                    <Col span={2}>
                        <Popover
                            placement="bottom"
                            content={targetWebsiteContent}
                            trigger={["hover"]}
                        >
                            <p type="primary">Site-Management</p>
                        </Popover>
                    </Col>
                    <Col span={2}>
                        <Popover
                            placement="bottom"
                            content={betManagmentContent}
                            trigger={["hover"]}
                        >
                            <p type="primary">Bet-Management</p>
                        </Popover>
                    </Col>
                    <Col span={2}>
                        <Popover
                            placement="bottom"
                            content={timeContent}
                            trigger={["hover"]}
                        >
                            <p type="primary">Time-Management</p>
                        </Popover>
                    </Col> */}

                    <Col span={10}>
                        {logedin ? (
                            <>
                                <>
                                    <Row justify={"end"} align={"middle"}>
                                        <Col span={8}>
                                            <Popover
                                                content={Content}
                                                trigger={["click", "hover"]}
                                                placement="bottom"
                                                arrow={true}
                                                onOpenChange={() => setCount(0)}
                                            >
                                                <Badge count={count}>
                                                    <Avatar
                                                        icon={<BellFilled />}
                                                    />
                                                </Badge>
                                            </Popover>
                                        </Col>
                                        <Col>
                                            <Dropdown
                                                menu={{ items }}
                                                pointAtCenter={true}
                                                trigger={["hover"]}
                                                placement="bottomCenter"
                                                overlayStyle={{
                                                    marginTop: "10vh",
                                                }}
                                            >
                                                <Avatar
                                                    size={40}
                                                    icon={<UserOutlined />}
                                                />
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </>
                            </>
                        ) : (
                          ""
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Header;
