import React from 'react'
import {Form,Button,Col,Row}  from "antd"
import TemplateForm from '../../Form/Template'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined,ArrowLeftOutlined } from '@ant-design/icons'
import Apicall from '../../Helper/Apicall'
import NotificationHandler from '../../Helper/NotificationHandler'

const NewTemplate = () => {
    const handleNewTemplate = async (value) => {
        let addTemplate = await Apicall("post", "addTemplate", value);
        if (addTemplate.success === 0) {
            NotificationHandler.error(addTemplate.message);
        } else {
            navigate("/templateList");
        }
    };
    const navigate = useNavigate();
    const [form] = Form.useForm();
    
    return (
        <div className="home_center">
            <Row
                style={{
                    margin: "2rem",
                }}
                justify
            >
                <ArrowLeftOutlined
                    onClick={() => {
                        navigate("/templateList");
                    }}
                />
                <Col span={10}>
                    <h3>New Template</h3>
                </Col>
            </Row>
            <Form
                name="newTemplate"
                form={form}
                layout="horizontal"
                onFinish={handleNewTemplate}
            >
                <TemplateForm />
                <Button
                    htmlType="submit"
                    type="primary"
                    icon={<PlusOutlined />}
                >
                    ADD
                </Button>
            </Form>
        </div>
    );
};

export default NewTemplate
