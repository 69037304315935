import React, { useState, useEffect } from "react";
import { List, Button, Row, Col } from "antd";
import Apicall from "../../Helper/Apicall";
import NotificationHandler from "../../Helper/NotificationHandler";
import CustomModal from "../CustomModal";
import EditComponent from "../EditComponent";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Listtargetwebsite from "../SiteManagment/Listtargetwebsite";
import AddWebsite from "../../Components/SiteManagment/AddWebsite"

const GroupList = () => {
    const [data, setData] = useState([]);
    const [open,setOpen] = useState(false)
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [webSiteData, setWebsiteData] = useState([]);

    const [webSiteExpanedKey, setWebSiteExpanedKey] = useState(null);



    const actionForSubgroup = async (
        groupName,
        groupId,
        subGroupName,
        subGroupId,
        deviceId,
        action,
        subItem
    ) => {
        const payload = {
            groupName: groupName,
            groupId: groupId,
            subGroupName: subGroupName,
            subGroupId: subGroupId,
            deviceId: deviceId,
        };

        if (action === "START") {
            payload["isStart"] = true;
        } else if (action === "STOP") {
            payload["isStart"] = false;
        } else {
            NotificationHandler.error("Something went Wrong");
        }

        const response = await Apicall("post", "updateGroup", payload);
        console.log(response,"response")
        if (!response.success) {
            return NotificationHandler.error(response.message);
        } else {
            getGroupList()
            return NotificationHandler.success("DATA UPDATED SUCCESSFULLY");
        }
    };

    const getGroupList = async () => {
        try {
            let groupData = await Apicall("post", "getAllGroups", {
                action: "all",
            });
            if (groupData.success === 0) {
                NotificationHandler.error(groupData.message);
            } else {
                setData(groupData.data);
            }
        } catch (error) {
            NotificationHandler.error("Failed to fetch group data.");
        }
    };

    const handleNewGroupClick = async () => {
        try {
            let groupData = await Apicall("post", "newGroup", {});
            if (groupData.success === 0) {
                NotificationHandler.error(groupData.message);
            } else {
                NotificationHandler.success(groupData.message);
                getGroupList()
            }
        } catch (error) {
            NotificationHandler.error("Failed to create new group.");
        }
    };
    const afterModalClose = async () => {
        getGroupList();
    };

    const handleItemClick = (record) => {
        if (expandedRowKey === record.srNo) {
            // Collapse the row if it's already expanded
            setExpandedRowKey(null);
        } else {
            // Expand the clicked row
            setExpandedRowKey(record.srNo);
        }
    };
    const handleWebsiteClick = (data) => {
        if (data.label === webSiteExpanedKey) {
            setWebSiteExpanedKey(null);
        } else {
            setWebSiteExpanedKey(data.label);
        }
    };
    const addWebSiteClick = (groupName,subGroupName,deviceId) => {
        return <AddWebsite  groupName ={groupName} subGroupName ={subGroupName} deviceId ={deviceId} />
    };

    useEffect(() => {
        getGroupList();
    }, []);

    return (
        <div className="home_center">
            <Row style={{ padding: "1rem" }}>
                <Col span={12}>
                    <Button type="primary" onClick={handleNewGroupClick}>
                        Add New Group
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        type="primary"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        REFRESH
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={6}>SrNO</Col>
                <Col span={6}>GroupName</Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
            </Row>
            <List
                bordered
                dataSource={data}
                renderItem={(item) => (
                    <React.Fragment>
                        <List.Item
                            key={item.srNo}
                            style={{
                                cursor: "pointer",
                                backgroundColor:
                                    expandedRowKey === item.srNo
                                        ? "#f0f0f0"
                                        : "",
                            }}
                        >
                            <Row
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <Col>
                                    {/* Plus icon */}
                                    <PlusOutlined
                                        style={{ fontSize: "16px" }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent event bubbling
                                            handleItemClick(item);
                                        }}
                                    />
                                </Col>
                                <Col span={4} style={{ flex: 1 }}>
                                    {item.srNo}
                                </Col>
                                <Col span={6} style={{ flex: 1 }}>
                                    {item.label}
                                </Col>
                                <Col span={6} style={{ flex: 1 }}>
                                    <CustomModal
                                        groupId={item.value}
                                        afterModalClose={afterModalClose}
                                    />
                                </Col>
                                <Col span={6} style={{ flex: 1 }}>
                                    <EditComponent
                                        page={"group"}
                                        pageId={item.value}
                                        rowData={item}
                                    />
                                </Col>
                            </Row>
                        </List.Item>
                        {expandedRowKey === item.srNo && (
                            <>
                                <Row
                                    style={{
                                        backgroundColor: "#a3ffb4",
                                        padding: "2rem",
                                    }}
                                >
                                    <Col span={4}>SrNO</Col>
                                    <Col span={4}>Sub GroupName</Col>
                                    <Col span={4}>Device Id</Col>
                                    <Col span={4}>Actions</Col>
                                </Row>
                                <List.Item
                                    key={`sub_${item.srNo}`}
                                    style={{ backgroundColor: "#a3ffb4" }}
                                >
                                    <List
                                        dataSource={item.subGroup}
                                        renderItem={(subItem) => (
                                            <>
                                                <List.Item key={subItem.srNo}>
                                                    <Row>
                                                        <Col>
                                                            {/* Plus icon */}
                                                            <PlusOutlined
                                                                style={{
                                                                    fontSize:
                                                                        "16px",
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation(); // Prevent event bubbling
                                                                    handleWebsiteClick(
                                                                        subItem
                                                                    );
                                                                }}
                                                            />
                                                        </Col>

                                                        <Col
                                                            span={3}
                                                            style={{
                                                                width: "10px",
                                                            }}
                                                        >
                                                            {subItem.srNo}
                                                        </Col>
                                                        <Col
                                                            span={5}
                                                            style={{
                                                                width: "300px",
                                                            }}
                                                        >
                                                            {subItem.label}
                                                        </Col>
                                                        <Col
                                                            span={5}
                                                            style={{
                                                                width: "300px",
                                                            }}
                                                        >
                                                            {subItem.deviceId}
                                                        </Col>
                                                        <Col
                                                            span={5}
                                                            style={{
                                                                width: "400px",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <Button type="text">
                                                                    <Link
                                                                        to={`/edit/subGroup/${subItem.value}`}
                                                                    >
                                                                        EDIT
                                                                    </Link>
                                                                </Button>
                                                                {subItem.isStart ? (
                                                                    <Button
                                                                        danger
                                                                        type="text"
                                                                        onClick={() => {
                                                                            const action =
                                                                                "STOP";
                                                                            actionForSubgroup(
                                                                                item.label,
                                                                                item.value,
                                                                                subItem.label,
                                                                                subItem.value,
                                                                                subItem.deviceId,
                                                                                action,
                                                                                subItem
                                                                            );
                                                                        }}
                                                                    >
                                                                        STOP
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        type="text"
                                                                        style={{
                                                                            backgroundColor:
                                                                                "green",
                                                                        }}
                                                                        onClick={() => {
                                                                            const action =
                                                                                "START";
                                                                            actionForSubgroup(
                                                                                item.label,
                                                                                item.value,
                                                                                subItem.label,
                                                                                subItem.value,
                                                                                subItem.deviceId,
                                                                                action,
                                                                                subItem
                                                                            );
                                                                        }}
                                                                    >
                                                                        START
                                                                    </Button>
                                                                )}

                                                                <AddWebsite
                                                                    groupName={
                                                                        item.label
                                                                    }
                                                                    subGroupName={
                                                                        subItem.label
                                                                    }
                                                                    deviceId={
                                                                        subItem.deviceId
                                                                    }
                                                                    afterModalClose={afterModalClose}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                                <Row>
                                                    {webSiteExpanedKey ===
                                                    subItem.label ? (
                                                        <Listtargetwebsite
                                                            data={
                                                                subItem.urlData
                                                            }
                                                            isStart={
                                                                subItem.isStart
                                                            }
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </Row>
                                            </>
                                        )}
                                    />
                                </List.Item>
                            </>
                        )}
                    </React.Fragment>
                )}
            />
        </div>
    );
};

export default GroupList;
