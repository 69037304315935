import React, { useEffect, useState } from "react";
import Apicall from "../../Helper/Apicall";
import { useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Form, Button } from "antd";
import { useNavigate } from "react-router-dom";
import TemplateForm from "../../Form/Template";
import NotificationHandler from "../../Helper/NotificationHandler";

const EditTemplate = () => {
    const { templateId } = useParams("templateId");
    const [templateCategoryCode, setTemplateCategoryCode] = useState("");
    const [templateCategoryName, setTemplateCategoryName] = useState("");

    const navigate = useNavigate();
    const getTemplate = async () => {
        let data = await Apicall("post", "getTemplateById", {
            templateId: templateId,
        });
        if (data.success === 1) {
            setTemplateCategoryCode(data.data.templateCategoryCode);
            setTemplateCategoryName(data.data.templateCategoryName);
        }else{
            NotificationHandler.error(data.message)
        }
    };
    useEffect(() => {
        getTemplate();
    }, []);
    
    const [form] = Form.useForm();
    const handleFormUpdate = async (value) => {
        value["templateId"] = templateId;
        let data = await Apicall("post", "updateTemplate", value);
        if (data.success === 1) {
            navigate("/templateList");
        }else{
            NotificationHandler.error(data.message)
        }
    };
    return (
        <div className="home_center">
            <Row
                style={{
                    margin: "2rem",
                }}
                justify
            >
                <ArrowLeftOutlined
                    onClick={() => {
                        navigate("/templateList");
                    }}
                />
                <Col span={10}>
                    <h>Edit Sub Group</h>
                </Col>
            </Row>

            {templateCategoryCode && templateCategoryName ? (
                <Form
                    form={form}
                    name="editTemplateForm"
                    layout="vertical"
                    initialValues={{
                        templateCategoryName: templateCategoryName,
                        templateCategoryCode: templateCategoryCode,
                    }}
                    onFinish={handleFormUpdate}
                >
                    <TemplateForm />
                    <Row style={{ margin: "1rem" }} justify={"center"}>
                        <Button htmlType="submit" type="primary">
                            UPDATE
                        </Button>
                    </Row>
                </Form>
            ) : (
                ""
            )}
        </div>
    );
};

export default EditTemplate;
