// AuthenticatedLayout.jsx
import React from 'react';

const Dashbord = ({ sidebar, content }) => (
  <div className='home_center'>
    <h2>Welcome to AutoBot Master Dashbord </h2>
      
  </div>
);

export default Dashbord;
