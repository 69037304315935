import React from 'react'
import NewGroupForm from '../../Form/NewGroupForm'
import { Button ,Form,notification,Row,Input,Col} from 'antd'
import {BackwardFilled, BackwardOutlined, CloseCircleOutlined, ArrowLeftOutlined , PlusOutlined}  from  '@ant-design/icons'
import Apicall from '../../Helper/Apicall'
import { useNavigate } from 'react-router-dom'
import NotificationHandler from '../../Helper/NotificationHandler'



const NewGroup = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();

    const handleNewGroupForm = async(value)=>{
         try {
            let data = await Apicall("post","newGroup",value)
            if(data.success === 1){
              navigate("/groupList")
            }else{
              NotificationHandler.error(data.message)
            }
         } catch (error) {
            notification.error(error.message)
         }
    }
  return (
    <div  className='home_center'>
        <Row style={{
            margin:"2rem"
        }} justify><ArrowLeftOutlined  onClick={()=>{navigate("/groupList")}}/>
        <Col span={10}>
            <h3>
            New Group

            </h3>
        </Col>
        </Row>
           {contextHolder}
      <Form name="newGroupForm"  form={form}  layout='horizontal' onFinish={handleNewGroupForm} >
        <NewGroupForm />
        <Button htmlType='submit' type='primary' icon ={<PlusOutlined />}>
             ADD
        </Button>
      </Form>
    </div>
  )
}

export default NewGroup
