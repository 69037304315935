import {
    Row,
    Table,
    Button,
    Col,
    notification,
    Modal,
    Select,
    DatePicker,
    Checkbox,
} from "antd";
import React, { useState, useEffect } from "react";
import Apicall from "../../Helper/Apicall";
import { epochConveter, reload } from "../../Helper/helper";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { groupArray, subGroupArray } from "../../Data/GroupData";

const NewJob = () => {
    const [jobList, setJobList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [preFilledStartDate, setPreFilledStartDate] = useState("");
    const [preFilledEndDate, setPreFilledEndDate] = useState("");
    const [group, setGroup] = useState([]);
    const [subGroup, setSubGroup] = useState([]);

    const navigate = useNavigate();
    const [newJob, setNewJob] = useState({
        marketName: "",
        startDate: "",
        endDate: "",
        groupName: "",
        subGroupName: "",
        isRepetative: false,
        isAutoGenerated: false,
    });

    const [oldJob, setOldJob] = useState({
        jobId: "",
        marketName: "",
        startDate: "",
        endDate: "",
        isRepetative: "",
        isAutoGenerated: "",
        groupName: "",
        subGroupName: "",
    });

    const editJob = (record) => {
        setEditModalOpen(true);
        setPreFilledStartDate(dayjs(record.startDate, dateFormatList));
        setPreFilledEndDate(dayjs(record.endDate, dateFormatList));
        setOldJob({
            ...oldJob,
            jobId: record.jobId,
            marketName: record.marketName,
            isAutoGenerated: record.isAutoGenerated,
            isRepetative: record.isRepetative,
            groupName: record.groupName,
            subGroupName: record.subGroupName,
        });
    };

    const getJobList = async () => {
        let response = await Apicall("get", "getAllJob", {});
        if (response.success === 0) {
            api.error({ message: response.message });
        } else {
            setJobList(response.data);
        }
    };

    //Updating  status of the Job
    const updateJobStatus = async (record, status) => {
        let payload = {
            jobId: record.jobId,
            status: status,
        };

        let response = await Apicall("post", "updateJobStatus", payload);
        if (response.success === 0) {
            api.error({ message: response.message });
        } else {
            getJobList();
        }
    };

    const betListPage = (record) => {
        navigate(`/betList/${record.jobId}`);
    };
    const betHitosry = (record) => {
        navigate(`/betHistory/${record.jobId}`);
    };

    const columns = [
        {
            title: "Sr No",
            dataIndex: "key",
            width: 80,
        },
        {
            title: "Market",
            dataIndex: "marketName",
            width: 150,
        },
        {
            title: "GroupName",
            dataIndex: "groupName",
            width: 150,
        },
        {
            title: "Sub Group Name",
            dataIndex: "subGroupName",
            width: 150,
        },
        {
            title: "From Date",
            dataIndex: "startDate",
        },
        {
            title: "To Date",
            dataIndex: "endDate",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "Action",
            key: "jobId",
            width: 300,
            render: (_, record) => {
                return (
                    <>
                        {(() => {
                            if (record.status === "NEW") {
                                return (
                                    <>
                                        <Row>
                                            <Col span={8}>
                                                <Button
                                                    type="primary"
                                                    onClick={() =>
                                                        editJob(record)
                                                    }
                                                >
                                                    Edit Job
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    onClick={() =>
                                                        updateJobStatus(
                                                            record,
                                                            "SENT-TO-GENERATION"
                                                        )
                                                    }
                                                >
                                                    Sent to Generation
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                );
                            } else if (record.status === "SENT-TO-GENERATION") {
                                return (
                                    <Button type="primary">
                                        Sent to Generation
                                    </Button>
                                );
                            } else if (record.status === "UNDER-GENERATION") {
                                return (
                                    <Row>
                                        <Button type="primary">
                                            Under Generation
                                        </Button>
                                    </Row>
                                );
                            } else if (record.status === "GENERATED") {
                                return (
                                    <Row>
                                        <Button
                                            type="primary"
                                            onClick={() => betListPage(record)}
                                        >
                                            Bet List
                                        </Button>
                                    </Row>
                                );
                            } else if (record.status === "PUBLISH") {
                                return (
                                    <Row>
                                        <Button
                                            type="primary"
                                            onClick={() => betHitosry(record)}
                                        >
                                            History
                                        </Button>
                                    </Row>
                                );
                            } else {
                                return (
                                    <>
                                        <Button>History</Button>
                                    </>
                                );
                            }
                        })()}
                    </>
                );
            },
        },
    ];

    const handleGroupClick = async () => {
        let data = await Apicall("post", "getAllGroups", {
            action: "jobGroupData",
        });
        if (data.success === 1){
            const temObj = {
                value: null,
                label:"FOR-ALL"
            };
            setGroup([...data?.data,temObj]);
        } else {
            return api.error({ message: data.message });
        }
    };

    const handleGroupChange = async (value, label) => {
        let data = await Apicall("post", "getAllGroups", {
            groupId: value,
            action: "subGroupData",
        });
        setNewJob({ ...newJob, groupName: label.label });
        setOldJob({ ...oldJob, groupName: label.label });
        
        if (data.success) {
            const temObj = {
                value: null,
                label: "FOR-ALL",
            };
            setSubGroup([...data.data, temObj]);
        }

    };

    const handleSubGroupChange = async (value, label) => {
        setNewJob({ ...newJob, subGroupName: label.label });
        setOldJob({ ...oldJob, subGroupName: label.label });
    };

    // calling the BetList Modal
    const addNewJob = async () => {
        let startDate = epochConveter(newJob.startDate);
        let endDate = epochConveter(newJob.endDate);
        let nowDate = Math.floor(new Date() / 1000);
        if (
            !newJob.marketName ||
            !newJob.startDate ||
            !newJob.endDate ||
            !newJob.groupName 
        ) {
            return api.error({ message: "Please Select the all values" });
        }

        if (endDate <= startDate) {
            api.error({ message: "End Date can not be less than start Date" });
        } else if (startDate < nowDate) {
            api.error({
                message: "Start  Time can not be less than current time",
            });
        } else {
            let payload = {
                marketName: newJob.marketName,
                startDate: newJob.startDate,
                endDate: newJob.endDate,
                isRepetative: newJob.isRepetative,
                isAutoGenerated: newJob.isAutoGenerated,
                groupName: newJob.groupName,
                subGroupName: newJob.subGroupName,
                status: "NEW",
                parentId: "",
            };
            let response = await Apicall("post", "newJob", payload);
            console.log(response);
            if (response.success === 0) {
                api.error({ message: response.message });
            } else {
                api.info({ message: "New Job create Successfully" });
                setModalOpen(false);
                getJobList();
            }
        }
        //window.location.reload()
    };

    const updateJob = async () => {
        if (preFilledEndDate < preFilledStartDate) {
            api.error({ message: "End Date can not be less than start Date" });
        } else {
            let payload = {
                jobId: oldJob.jobId,
                marketName: oldJob.marketName,
                startDate: preFilledStartDate,
                endDate: preFilledEndDate,
                status: "NEW",
                groupName: oldJob.groupName,
                subGroupName: oldJob.subGroupName,
                isRepetative: oldJob.isRepetative,
                isAutoGenerated: oldJob.isAutoGenerated,
                parentId: oldJob.parentId ?? "",
            };

            let response = await Apicall("post", "newJob", payload);

            if (response.success === 0) {
                api.error({ message: response.message });
            } else {
                setEditModalOpen(false);
                api.info({ message: "Update successfully" });
                setOldJob({
                    jobId: "",
                    marketName: "",
                    startDate: "",
                    groupName: "",
                    subGroupName: "",
                    endDate: "",
                });
            }
        }
        //window.location.reload()
    };

    const setMarket = (value) => {
        setNewJob({ ...newJob, marketName: value });
    };

    const setOldMarket = (value) => {
        setOldJob({ ...oldJob, marketName: value });
    };

    const setFromDate = (value) => {
        let fromDate = `${value.$y}-${value.$M + 1}-${value.$D} ${value.$H}:${
            value.$m
        }`;
        setNewJob({ ...newJob, startDate: fromDate });
    };

    const setToDate = (value) => {
        let toDate = `${value.$y}-${value.$M + 1}-${value.$D} ${value.$H}:${
            value.$m
        }`;
        setNewJob({ ...newJob, endDate: toDate });
    };

    const closeModal = () => {
        setNewJob({
            marketName: "",
            startDate: "",
            endDate: "",
        });
        setModalOpen(false);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
        setOldJob({
            jobId: "",
            marketName: "",
            startDate: "",
            endDate: "",
        });
    };

    const refreshPage = () => {
        window.location.reload();
    };
    /**
     * 
     * formatting Date
            
     */

    const formateDate = (dateString) => {
        const [day, month, year, hours, minutes] = dateString.split(/[\s:-]+/);
        const dateObject = new Date(year, month - 1, day, hours, minutes);
        // Get the epoch time (milliseconds since January 1, 1970)
        const epochTime = dateObject.getTime();
        return epochTime;
    };
    const setOldFromDate = (value) => {
        let fromOldDate = `${value.$D}-${value.$M + 1}-${value.$y} ${
            value.$H
        }:${value.$m}`;
        setPreFilledStartDate(dayjs(formateDate(fromOldDate, dateFormatList)));
        setOldJob({ ...oldJob, startDate: fromOldDate });
    };

    const setOldToDate = (value) => {
        let toOldDate = `${value.$D}-${value.$M + 1}-${value.$y}  ${value.$H}:${
            value.$m
        }`;
        setPreFilledEndDate(dayjs(formateDate(toOldDate, dateFormatList)));
        setOldJob({ ...oldJob, endDate: toOldDate });
    };

    const onReptetiveClick = () => {
        setNewJob({ ...newJob, isRepetative: !oldJob.isRepetative });
    };

    const onAutogenrateClick = (e) => {
        setNewJob({ ...newJob, isAutoGenerated: !oldJob.isAutoGenerated });
    };
    const onAutoGenrateEdit = () => {
        setOldJob({ ...oldJob, isAutoGenerated: !oldJob.isAutoGenerated });
    };
    const onReapeatEdit = (e) => {
        setOldJob({ ...oldJob, isRepetative: !oldJob.isRepetative });
    };
    const dateFormatList = [
        "DD/MM/YYYY  HH:mm",
        "DD/MM/YY",
        "DD-MM-YYYY",
        "DD-MM-YY",
    ];

    useEffect(() => {
        getJobList();
    }, []);

    return (
        <div className="home_center">
            {contextHolder}
            <Row justify={"center"}>
                <h1>Create New Job</h1>
            </Row>
            <Row justify={"center"} gutter={30}>
                <Col span={8}>
                    <Button
                        type="primary"
                        onClick={(e) => {
                            setModalOpen(true);
                        }}
                        className="input"
                    >
                        Add New Job
                    </Button>
                </Col>
                <Col span={8}>
                    <Button type="primary" onClick={refreshPage}>
                        Refresh
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={jobList}
                scroll={{
                    y: 300,
                }}
            />
            {/**New JOb MODAL */}
            <Modal
                maskClosable={false}
                closeIcon={false}
                onCancel={closeModal}
                open={modalOpen}
                onOk={addNewJob}
                okText={"Add"}
            >
                <div>
                    <Row justify={"center"}>
                        <h2>Add New Job</h2>
                    </Row>
                    <Row className="input">
                        Market
                        <Select
                            style={{ width: 1000 }}
                            value={newJob.marketName}
                            options={[
                                {
                                    label: "CAMBODIA",
                                    value: "cambodia",
                                },
                                {
                                    label: "SYDNEY",
                                    value: "sydney",
                                },
                                {
                                    label: "CHINA",
                                    value: "china",
                                },
                                {
                                    label: "JAPAN",
                                    value: "japan",
                                },
                                {
                                    label: "SINGAPORE",
                                    value: "singapore",
                                },
                                {
                                    label: "GRABPOOLS",
                                    value: "grabpools",
                                },
                                {
                                    label: "MONGOLIA",
                                    value: "mongolia",
                                },
                                {
                                    label: "TAIWAN",
                                    value: "taiwan",
                                },
                                {
                                    label: "NUSANTARA",
                                    value: "nusantara",
                                },
                                {
                                    label: "HONGKONG",
                                    value: "hongkong",
                                },
                            ]}
                            onChange={setMarket}
                        />
                    </Row>
                    <Row>
                        <Col> Select Group</Col>
                        <Select
                            style={{ width: "100%" }}
                            options={group}
                            onClick={handleGroupClick}
                            onChange={handleGroupChange}
                        />
                    </Row>
                    <Row>
                        <Col> Select Sub-Group</Col>
                        <Select
                            style={{ width: "100%" }}
                            options={subGroup}
                            onChange={handleSubGroupChange}
                        />
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col>
                            <Col span={10}>From Date</Col>
                            <DatePicker
                                showTime
                                allowClear={false}
                                format={dateFormatList}
                                onOk={setFromDate}
                                showNow={false}
                            />
                        </Col>
                        <Col>
                            <Col span={10}>To Date</Col>
                            <DatePicker
                                showTime
                                format={dateFormatList}
                                allowClear={false}
                                showNow={false}
                                onOk={setToDate}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={10}>Is-Reptetive</Col>
                        <Col span={10}>
                            <Checkbox
                                onChange={onReptetiveClick}
                            ></Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>Is-Autogenrated</Col>
                        <Col span={10}>
                            <Checkbox
                                onChange={onAutogenrateClick}
                            ></Checkbox>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/** EDIT JOB MODAL  */}
            <Modal
                open={editModalOpen}
                maskClosable={false}
                closeIcon={false}
                onCancel={closeEditModal}
                okText={"UPDATE"}
                onOk={updateJob}
            >
                <div>
                    <Row justify={"center"}>
                        <h2>Edit Job</h2>
                    </Row>
                    <Row className="input">
                        Market
                        <Select
                            style={{ width: 1000 }}
                            value={oldJob.marketName}
                            options={[
                                {
                                    label: "CAMBODIA",
                                    value: "cambodia",
                                },
                                {
                                    label: "SYDNEY",
                                    value: "sydney",
                                },
                                {
                                    label: "CHINA",
                                    value: "china",
                                },
                                {
                                    label: "JAPAN",
                                    value: "japan",
                                },
                                {
                                    label: "SINGAPORE",
                                    value: "singapore",
                                },
                                {
                                    label: "GRABPOOLS",
                                    value: "grabpools",
                                },
                                {
                                    label: "MONGOLIA",
                                    value: "mongolia",
                                },
                                {
                                    label: "TAIWAN",
                                    value: "taiwan",
                                },
                                {
                                    label: "NUSANTARA",
                                    value: "nusantara",
                                },
                                {
                                    label: "HONGKONG",
                                    value: "hongkong",
                                },
                            ]}
                            onChange={setOldMarket}
                        />
                    </Row>
                    <Row>
                        <Col> Select Group</Col>
                        <Select
                            style={{ width: "100%" }}
                            options={group}
                            onClick={handleGroupClick}
                            onChange={handleGroupChange}
                            value={oldJob.groupName}
                        />
                    </Row>
                    <Row>
                        <Col> Select subGroup </Col>
                        <Select
                            value={oldJob.subGroupName}
                            style={{ width: "100%" }}
                            onChange={handleSubGroupChange}
                            options={subGroup}
                        />
                    </Row>
                    <Row>
                        <Col>
                            <Col span={10}>From Date</Col>
                            <DatePicker
                                value={preFilledStartDate}
                                showTime
                                format={dateFormatList}
                                allowClear={true}
                                onOk={setOldFromDate}
                                showNow={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Col span={10}>To Date</Col>
                            <DatePicker
                                value={preFilledEndDate}
                                showTime
                                allowClear={false}
                                showNow={false}
                                format={dateFormatList}
                                onOk={setOldToDate}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>Is-Reptetive</Col>
                        <Col span={10}>
                            <Checkbox
                                checked={oldJob.isRepetative}
                                onChange={onReapeatEdit}
                            ></Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>Is-Autogenrated</Col>
                        <Col span={10}>
                            <Checkbox
                                checked={oldJob.isAutoGenerated}
                                onChange={onAutoGenrateEdit}
                            ></Checkbox>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default NewJob;
