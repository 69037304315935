import { Input, Button, Select, notification, Row, Col } from "antd";
import React, { useState } from "react";
import Apicall from "../../Helper/Apicall";
import Loader from "../../Helper/Loader";

const Newuser = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [userRole, setUserRole] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const handleChange = (value) => {
        setUserRole(value);
    };

    const newUser = async () => {
        let paylod = { userName: userName, password: password, role: userRole };

        try {
            if (!userName || !password || !userRole) {
                throw new Error("please fill all value");
            } else {
                let regex =
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                let check = userName.match(regex);
                if (check === null) {
                    throw new Error(`User name Must be email id`);
                }
                setLoading(true);
                let response = await Apicall("post", "newUser", paylod);
                if (response.success === 0) {
                    throw new Error(response.message);
                } else {
                    api.info({
                        message: "User added successfull",
                        duration: 1,
                    });
                    setPassword("");
                    setUserName("");
                    setUserRole("");
                    setLoading(false);
                }
            }
        } catch (error) {
            api.error({ message: error.message, duration: 1 });
            setLoading(false);
        }
    };
    return (
        <div>
            {contextHolder}

            {loading ? (
                <Loader message={"Updating User Please Wait"} />
            ) : (
                <div className="home_center">
                    <h2 style={{ textAlign: "center" }}>Add New User</h2>
                    <Row className="center" justify={"center"}>
                        <Col span={2}>Username : </Col>
                        <Col span={8}>
                            <Input
                                className="input"
                                value={userName}
                                placeholder="enter username ,avoid space in username"
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="input" justify={"center"}>
                        <Col span={2}>Password : </Col>
                        <Col span={8}>
                            <Input
                                classNames={"inputTag"}
                                type="password"
                                className="input"
                                value={password}
                                placeholder=" user password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row justify={"center"}>
                        <Col span={2}>Select User Role : </Col>
                        <Col span={8}>
                            <Select
                                className="input"
                                value={userRole}
                                placeholder="select user Role"
                                style={{
                                    width: 480,
                                }}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: "USER",
                                        label: "User",
                                    },
                                    {
                                        value: "ADMIN",
                                        label: "Admin",
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <br />
                    <Button type="primary" onClick={newUser}>
                        Add User
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Newuser;
