import axios from "axios";

export default async function Apicall(method, route, paylod) {
    let token = localStorage.getItem("token");
    const config = {
        method: method,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            token: token,
        },
        url: `${process.env.REACT_APP_URL}autobot/${route}`,
        data: paylod,
    };

    try {
        const response = await axios(config);
        let apiData = response.data;
        return apiData;
    } catch (error) {
        let apiData = { success: 0, message: error.message };
        return apiData;
    }
}
