import { Row, Select, Col, Input, Button ,DatePicker} from "antd";
import React, { useState } from "react";
import { betType, marketOption } from "../../Data/GroupData";
import { useNavigate } from "react-router-dom";
import Apicall from "../../Helper/Apicall";
import NotificationHandler from "../../Helper/NotificationHandler";
import { useEffect } from "react";
const AddNumber = () => {
    const navigate = useNavigate();
    const [groupOption, setGroupOption] = useState([]);
    const [subGroupOption, setsubGroupOption] = useState([]);
    const [groupId, setGroupId] = useState("");

    const [number, setNumber] = useState({
        groupName: "",
        groupId:"",
        subGroupName: "",
        subGroupId:"",
        type: "",
        bet: "",
        amount: "",
        marketName: "",
        time:""
    });
    const setDate = (value) => {
        let toDate = `${value.$y}-${value.$M + 1}-${value.$D} ${value.$H}:${
            value.$m
        }`;
        setNumber({ ...number, time: toDate });
    };
    const addNumber = async () => {
        console.log(number);
        if (
            !number.amount ||
            !number.marketName ||
            !number.bet ||
            !number.groupName ||
            !number.subGroupName ||
            !number.type ||
            !number.time
        ) {
            return NotificationHandler.error("Pleas Fill All Value");
        }

        const response = await Apicall("post", "addNumber", number);
        if (response.success === 0) {
            return NotificationHandler.error(response.message);
        } else {
            setNumber({});
            return NotificationHandler.success(response.message);
        }
    };
    const getGroupOption = async () => {
        const response = await Apicall("post", "getAllGroups", {
            action: "groupData",
        });
        if (response.success === 0) {
            navigate("/");
            return NotificationHandler.error(response.message);
        } else {
            setGroupOption(response.data);
        }
    };
    const getSubGroupOption = async () => {
        if (!number.groupName)
            return NotificationHandler.error("Please Select Group First");
        const response = await Apicall("post", "getAllGroups", {
            action: "subGroupData",
            groupId: groupId,
        });
        if (response.success === 0) {
            navigate("/");
            return NotificationHandler.error(response.message);
        } else {
            setsubGroupOption(response.data);
        }
    };

    return (
        <div className="home_center">
            <Row> ADD NUMBER</Row>
            <Row gutter={[20, 20]} style={{ margin: 20 }}>
                <Col span={8}>
                    <Select
                        placeholder="SELECT GROUP"
                        options={groupOption}
                        onChange={(value, label) => {
                            setNumber({ ...number, groupName: label.label ,groupId:value});
                            setGroupId(value);
                        }}
                        onClick={getGroupOption}
                        style={{ width: 200 }}
                    />
                </Col>
                <Col span={8}>
                    <Select
                        placeholder="SELECT SUB GROUP"
                        options={subGroupOption}
                        onChange={(value, label) =>
                            setNumber({ ...number, subGroupName: label.label,subGroupId:value })
                        }
                        style={{ width: 200 }}
                        onClick={getSubGroupOption}
                    />
                </Col>
                <Col span={8}>
                    <Select
                        placeholder="SELECT BET TYPE"
                        options={betType}
                        onChange={(value, label) =>
                            setNumber({ ...number, type: label.label })
                        }
                        style={{ width: 200 }}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={12}>Enter Bet Number</Col>
                        <Col span={12}>
                            <Input
                                onChange={(e) => {
                                    setNumber({
                                        ...number,
                                        bet: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={12}>Enter Bet Amount</Col>
                        <Col span={12}>
                            <Input
                                onChange={(e) => {
                                    setNumber({
                                        ...number,
                                        amount: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{ marginTop: "2rem" }}>
                <Col span={8}>
                    <Select
                        placeholder={"SELECT MARKET"}
                        options={marketOption}
                        onChange={(value, label) => {
                            setNumber({ ...number, marketName: label.label });
                        }}
                    />
                </Col>
                <Col span={8}>
                    <DatePicker
                        placeholder="Select Time"
                        showTime
                        format={"DD/MM/YYYY  HH:mm"}
                        allowClear={false}
                        showNow={false}
                        onOk={setDate}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: "2rem" }}>
                <Col span={12}>
                    <Button onClick={() => navigate("/")}>BACK</Button>
                </Col>
                <Col span={12}>
                    <Button type="primary" onClick={addNumber}>
                        ADD
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default AddNumber;
