import React, { useState } from "react";
import { Button, Modal } from "antd";
import NewSubGroup from "./GroupMaster/NewSubGroup";

const CustomModal = ({ groupId, afterModalClose }) => {
    const [open, setOpen] = useState(false);
    const openModal = () => {
        setOpen(!open);
    };
    const afterSubmission = () => {
        setOpen(!open);
        afterModalClose();

    };
    return (
        <div>
            {!open ? (
                <Button type="primary" onClick={openModal}>
                    Add SubGroup
                </Button>
            ) : (
                <Modal
                    title={"ADD NEW SUB GROUP"}
                    open={open}
                    onCancel={() => setOpen(!open)}
                    maskClosable={true}
                    keyboard={false}
                    width={"90%"}
                    footer={null}
                >
                    <NewSubGroup
                        groupId={groupId}
                        afterSubmission={afterSubmission}
                    />
                </Modal>
            )}
        </div>
    );
};

export default CustomModal;
