import React, { useState, useEffect } from "react";
import { subGroupColumns } from "../../Data/AddGroupData";
import { Button, Table, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Apicall from "../../Helper/Apicall";
import NotificationHandler from "../../Helper/NotificationHandler";

const SubGroupList = ({ record }) => {
        
    return (
        <div>
            <Table
                columns={subGroupColumns}
                dataSource={record ? record :[]}
                pagination={false}
            />
        </div>
    );
};

export default SubGroupList;
