import React from "react";
import { Form, Input, Col } from "antd";

const Template = () => {
    return (
        <div>
            <Form.Item
                label={"Template Name"}
                name={"templateCategoryName"}
                labelAlign="left"
                labelCol={{ span: 6 }}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={"Template Code"}
                name={"templateCategoryCode"}
                labelAlign="left"
                labelCol={{ span: 6 }}
            >
                <Input />
            </Form.Item>
        </div>
    );
};

export default Template;
