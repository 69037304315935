import React, { useEffect, useState } from "react";
import {Row,Col,App} from "antd"

import "../App.css";
import Apicall from "../Helper/Apicall";
import Sidebar from "../Components/Sidebar"
import Newuser from "./UserManagment/Newuser";

const Home = ({sidebar , content}) => {
    const [name, setName] = useState(false);

    const checkLogin = async () => {
        try {
            let response = await Apicall("post", "authenticate");
            if (response.success === 1) {
                setName(response.data.userName);
            } else {
                localStorage.clear();
                window.location.replace("/");
            }
        } catch (error) {}
    };

    useEffect(() => {
        checkLogin();
    }, []);
    return (
        <App>
            <Row>
                <Col span={4}>{sidebar}</Col>
                <Col span={8}>{content}</Col>
            </Row>
        </App>
    );
};

export default Home;
