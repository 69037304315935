import React, { useState } from "react";
import { Button, Modal } from "antd";
import EditGroup from    "./GroupMaster/EditGroup.jsx"
import {useNavigate}  from "react-router-dom"

const EditComponent = ({page, pageId,rowData}) => {
    const navigate = useNavigate()    
    const handleEdit = ()=>{
          navigate(`/edit/${page}/${pageId}`)
    }
  
    return (
        <div key={pageId}>
                <>
                    <Button onClick={() => handleEdit(page, pageId)}>
                        EDIT
                    </Button>
                </>
 
        </div>
    );
};

export default EditComponent;
