import EditComponent from "../Components/EditComponent";
import CustomModal from "../Components/CustomModal";
import { Button, Flex, List, Row, Segmented } from "antd";

export const addGroupColumns = [
    {
        title: "SrNo",
        dataIndex: "srNo",
        key: "srNo",
    },
    {
        title: "Group Name",
        dataIndex: "label",
        index: "groupName",
    },
    {
        title: "SubGroup",
        render: (_, record) => {
            return <CustomModal groupId={record.value} />;
        },
    },
    {
        title: "Action",
        render: (_, menu) => {
            return (
                <EditComponent
                    page={"group"}
                    pageId={menu.value}
                    rowData={menu}
                />
            );
        },
    },
];

export const subGroupColumns = [
    {
        title: "SrNo",
        dataIndex: "srNo",
        key: "srNo",
    },
    {
        title: "Sub Group Name",
        dataIndex: "label",
        key: "subGroupName",
        width: 300,
    },
    {
        title: "Device Id",
        dataIndex: "deviceId",
        index: "deviceId",
    },
    {
        title: "Actions",
        width:350,
        render: (_, menu) => {
            return (
                <Row itemLayout="horizontal " size="small" style={{padding:"10px"}}>
                    <List.Item>
                        <Button type="text">EDIT</Button>
                    </List.Item>
                    <List.Item>
                        <Button
                            type="text"
                            style={{ backgroundColor: "green" }}
                        >
                            START
                        </Button>
                    </List.Item>
                    <List.Item>
                        <Button danger type="text">
                            STOP
                        </Button>
                    </List.Item>
                </Row>
            );
        },
    },
];
